const moduleName = 'photos';

module.exports = () => {

	Array.from(document.querySelectorAll('.js-photos')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const holder = self;
		const showBtn = holder.querySelector('.js-photos-show-button');

		showBtn.addEventListener('click', (event) => {
			holder.classList.add('_open');

			event.preventDefault();
		});
	});

};