require('@fancyapps/fancybox/dist/jquery.fancybox');

module.exports = () => {

	$('[data-fancybox]').fancybox({
		loop: true,
		animationEffect: 'fade',
		transitionEffect: 'slide',
		buttons: ['close']
	});

};