const moduleName = 'gallery';

const Swiper = require('swiper');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-gallery')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const gallery = self;
		const slider = gallery.querySelector('.js-gallery-slider');
		const prevBtn = gallery.querySelector('.js-gallery-prev');
		const nextBtn = gallery.querySelector('.js-gallery-next');

		const gallerySwiper = new Swiper(slider, {
			slidesPerView: 'auto',
			navigation: {
				prevEl: prevBtn,
				nextEl: nextBtn,
				disabledClass: '_disabled'
			}
		});
	});
	
};