const moduleName = 'collapsed';

module.exports = () => {

	Array.from(document.querySelectorAll('.js-collapsed')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const holder = self;
		const toggle = holder.querySelector('.js-collapsed-toggle');
		const body = holder.querySelector('.js-collapsed-body');

		toggle.addEventListener('click', (event) => {
			if (holder.classList.contains('_open')) {
				slideUpBody();
			} else {
				slideDownBody();
			}

			event.preventDefault();
		});



		function slideDownBody() {
			const bodyHeight = body.offsetHeight;

			body.style.height = 0;
			holder.classList.add('_open');

			setTimeout(() => {
				body.style.height = bodyHeight + 'px';

				setTimeout(() => {
					body.style.height = '';
				}, 200);
			}, 0);
		}

		function slideUpBody() {
			body.style.height = body.offsetHeight + 'px';

			setTimeout(() => {
				body.style.height = 0;

				setTimeout(() => {
					body.style.height = '';
					holder.classList.remove('_open');
				}, 200);
			}, 0);
		}
	});

};