const moduleName = 'nav';

module.exports = () => {

	Array.from(document.querySelectorAll('.js-nav')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const nav = self;
		const submenusArray = Array.from(nav.querySelectorAll('.js-nav-submenu'));

		submenusArray.forEach((submenu) => {
			const holder = submenu.closest('.js-nav-submenu-holder');
			const submenuToggle = holder.querySelector('.js-nav-submenu-toggle');
			let windowWidth = document.body.clientWidth;

			holder.addEventListener('mouseenter', fadeInSubmenu);
			holder.addEventListener('mouseleave', fadeOutSubmenu);

			submenuToggle.addEventListener('click', () => {
				if (holder.classList.contains('_open')) {
					slideUpSubmenu();
				} else {
					slideDownSubmenu();
				}
			});

			window.addEventListener('resize', () => {
				if (holder.classList.contains('_open')) {
					if (windowWidth > 1024 && document.body.clientWidth <= 1024) {
						slideUpSubmenu();
					}

					if (windowWidth <= 1024 && document.body.clientWidth > 1024) {
						fadeOutSubmenu();
					}
				}

				windowWidth = document.body.clientWidth;
			});



			function fadeInSubmenu() {
				if (document.body.clientWidth > 1024) holder.classList.add('_open');
			}

			function fadeOutSubmenu() {
				if (document.body.clientWidth > 1024) holder.classList.remove('_open');
			}

			function slideDownSubmenu() {
				const submenuHeight = submenu.offsetHeight;

				submenu.style.height = 0;
				holder.classList.add('_open');

				setTimeout(() => {
					submenu.style.height = submenuHeight + 'px';

					setTimeout(() => {
						submenu.style.height = '';
					}, 200);
				}, 0);
			}

			function slideUpSubmenu() {
				submenu.style.height = submenu.offsetHeight + 'px';

				setTimeout(() => {
					submenu.style.height = 0;

					setTimeout(() => {
						submenu.style.height = '';
						holder.classList.remove('_open');
					}, 200);
				}, 0);
			}
		});
	});

};