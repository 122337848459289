const moduleName = 'header';

module.exports = () => {

	Array.from(document.querySelectorAll('.js-header')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const header = self;
		const headerOpenBtn = header.querySelector('.js-header-open-button');
		const headerCloseBtn = header.querySelector('.js-header-close-button');
		const headerMenu = header.querySelector('.js-header-menu');
		const headerPopupBtn = header.querySelector('.js-header-popup-button');
		let windowWidth = document.body.clientWidth;

		headerOpenBtn.addEventListener('click', showMenu);
		headerCloseBtn.addEventListener('click', hideMenu);
		headerPopupBtn.addEventListener('click', () => {
			header.classList.remove('_open');
		});

		document.addEventListener('click', (event) => {
			if (!event.target.closest('.js-header-menu') && 
				header.classList.contains('_open') && 
				!event.target.closest('.js-header-open-button')) {
				hideMenu();
			}
		});

		window.addEventListener('resize', () => {
			if (header.classList.contains('_open') && windowWidth <= 1024 && document.body.clientWidth > 1024) {
				hideMenu();
			}

			windowWidth = document.body.clientWidth;
		});



		function showMenu() {
			header.classList.add('_open');
			overlay().show();
		}

		function hideMenu() {
			header.classList.remove('_open');
			overlay().hide();
		}
	});

};