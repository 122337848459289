const moduleName = 'promo';

const Swiper = require('swiper');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-promo')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const promo = self;
		const promoSwiper = new Swiper(promo, {
			effect: 'fade',
			loop: true,
			speed: 500,
			autoplay: {
				delay: 5000
			}
		});
	});
	
};