// polyfills
require('core-js/fn/array/from');
require('./modules/polyfills')();

// detect mobile devices
require('./modules/mobileDetect')();

const $ = jQuery = window.jQuery = window.$ = require('jquery');

const overlay = window.overlay = require('./modules/overlay');
const popup = window.popup = require('./modules/popup');

const initModules = window.initModules = () => {
	popup();

	require('./modules/promo')();
	require('./modules/gallery')();
	require('./modules/nav')();
	require('./modules/header')();
	require('./modules/fancybox')();
	require('./modules/collapsed')();
	require('./modules/photos')();
};

if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
	initModules();
} else {
	document.addEventListener('DOMContentLoaded', initModules);
}